import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import defaultImage from '@assets/defaultImage.png';
import { Button, Modal, PageHeader, Restricted } from '@components';
import { useConfirmationModal } from '@hooks/useConfirmationModal';
import { Category } from '@interfaces/Category';
import CategoriesAPICaller from '@services/api/categories';
import { validateUUIDFormat } from 'utils/string/uuid';

import { CategoriesForm } from '../CategoriesForm';
import { CategoriesGroupsTable } from '../CategoriesGroupsTable';

export default function CategoriesView() {
  const { uuid } = useParams();
  const [data, setData] = useState<Category | null>(null);
  const [editId, setEditId] = useState('');
  const navigate = useNavigate();
  const confirmation = useConfirmationModal();

  const fetch = (uuid: string) => {
    CategoriesAPICaller.fetch(uuid).then((values) =>
      setData(values as Category)
    );
  };

  useEffect(() => {
    if (uuid) {
      if (validateUUIDFormat(uuid)) {
        fetch(uuid);
      } else {
        toast.error('ID informado inválido');
        navigate(-1);
      }
    }
  }, [uuid, navigate]);

  const deleteCategories = (id: string) => {
    confirmation({
      title: 'Excluir Categoria',
      confirmButtonTitle: 'Excluir',
      description: `Deseja realmente excluir a categoria ${data!.name}`,
      onSubmit: () => CategoriesAPICaller.remove(id).then((_) => navigate(-1)),
    });
  };

  return (
    <>
      <div className="p-s-200">
        <PageHeader backButton title="Categoria" />
        <div className="card card__body p-s-350 bg-white">
          <div className="row">
            <div className="col-md-6">
              <h4>Nome</h4>
              <span>{data?.name || <Skeleton style={{ width: '98%' }} />}</span>
            </div>

            <div className="col-md-6">
              <h4>Cor</h4>
              {data ? (
                <span className="d-flex align-items-center">
                  <div
                    className="color-selected"
                    style={{
                      backgroundColor: data.color,
                      marginRight: 10,
                      height: 35,
                      width: 35,
                    }}
                  />
                  {data.color}
                </span>
              ) : (
                <Skeleton style={{ width: '98%' }} />
              )}
            </div>

            <div className="col-md-6  mt-s-250">
              <h4>Exibir no Tour?</h4>
              <div className="row d-flex align-items-center">
                <span>{data?.showOnTour ? 'Ativo' : 'Inativo'}</span>
                <div
                  className={`little-ball-${
                    data?.showOnTour ? 'green' : 'red'
                  }`}
                ></div>
              </div>
            </div>
            <div className="col-md-6  mt-s-250">
              <h4>Status</h4>
              <div className="row d-flex align-items-center">
                <span>{data?.active ? 'Ativo' : 'Inativo'}</span>
                <div
                  className={`little-ball-${data?.active ? 'green' : 'red'}`}
                ></div>
              </div>
            </div>

            <div className="col-md-12  mt-s-250">
              <h4>Ícone</h4>
              {data ? (
                <img
                  src={data.cover || defaultImage}
                  alt="cover"
                  height={260}
                  className="border-radius-100"
                />
              ) : (
                <Skeleton height={150} style={{ width: '98%' }} />
              )}
            </div>

            <div className="col-md-12  mt-s-250">
              {data ? (
                <CategoriesGroupsTable
                  key={data.id}
                  categoryId={data.id!}
                  categoryName={data.name}
                  onUpdate={() => fetch(data.id!)}
                />
              ) : (
                <Skeleton height={150} style={{ width: '98%' }} />
              )}
            </div>
          </div>

          {data ? (
            <div className="row mt-s-300">
              <Restricted context="categories" resource="update">
                <div className="col mr-s-100">
                  <Button
                    onClick={() => setEditId(uuid!)}
                    design="outlined"
                    size="medium"
                  >
                    Editar informações
                  </Button>
                </div>
              </Restricted>
              <Restricted context="categories" resource="delete">
                <div className="col mr-s-100">
                  <Button
                    onClick={() => deleteCategories(uuid!)}
                    design="outlined"
                    size="medium"
                    color="negative"
                  >
                    Excluir categoria
                  </Button>
                </div>
              </Restricted>
            </div>
          ) : (
            <Skeleton
              height={40}
              style={{ width: '98%' }}
              className="mt-s-300"
            />
          )}
        </div>
      </div>

      {!!(uuid && editId) && (
        <Modal
          isOpen={!!editId}
          onClickAway={() => {
            setEditId('');
          }}
        >
          <CategoriesForm
            editId={editId}
            onSave={() => {
              fetch(uuid);
              setEditId('');
            }}
            closeModal={() => {
              setEditId('');
            }}
          />
        </Modal>
      )}
    </>
  );
}
