import { useForm } from 'react-hook-form';

import {
  Button,
  ColorPickerInput,
  FileUpload,
  Input,
  Switch,
} from '@components';
import CategoryAPICaller from '@services/api/categories';
import { formColorPattern } from '@validations/color';

interface Props {
  closeModal: () => void;
  onSave: () => void;
  editId?: string;
}

export default function CategoriesForm(props: Props) {
  const { createOrUpdate, fetch } = CategoryAPICaller;

  const {
    register,
    setError,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm({
    defaultValues: async () => fetchData(),
  });

  const fetchData = async () => {
    if (props.editId) {
      const category = await fetch(props.editId);

      return category;
    }
    return null;
  };

  return (
    <div className="container p-s-200">
      <div className="modal-title">
        <h3>{props.editId ? 'Editar' : 'Cadastrar'} categoria</h3>
      </div>
      <form
        autoComplete="off"
        className="form-max-height"
        onSubmit={handleSubmit((data) => {
          return createOrUpdate(data, setError).then((res) => {
            if (res.data.errors) return;
            props.closeModal();
            props.onSave();
          });
        })}
      >
        <div className="row grid-gap-1">
          <div className="col-md-10">
            <Input
              error={!!errors.name}
              caption={errors.name?.message as string}
              label="Nome"
              placeholder="Nome"
              form={register('name', { required: 'Obrigatório' })}
              disabled={isLoading}
            />
          </div>

          <div className="col-md-2">
            <Switch
              className="mt-s-500 float-right"
              caption={watch('active', 'Ativo') ? 'Ativo' : 'Inativo'}
              error={errors.active?.message as string}
              form={register('active', { value: true })}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="row grid-gap-1">
          <div className="col-md-12">
            <ColorPickerInput
              label="Cor"
              colorSelected={watch('color')}
              error={!!errors.color}
              caption={errors.color?.message as string}
              form={register('color', { ...formColorPattern })}
              onSelect={(color: String) => {
                setValue('color', color);
              }}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <label className="form-input__label">Ícone</label>
            <FileUpload
              uploadPreview
              fileName=""
              image={watch('cover')}
              imageHeight={200}
              accept="*.svg"
              maxSizeInBytes={5_000_000}
              disclaimer={`SVG do ícone`}
              isRemovable
              hideLabel
              form={register('cover')}
              onChange={([file]) => {
                setValue('cover', file);
              }}
              label="Escolher o ícone"
              error={errors.cover?.message as string}
            />
          </div>
        </div>
        <div className="row grid-gap-1">
          <div className="col-md-12">
            <Switch
              className="mt-s-100"
              label="Exibir no Tour?"
              caption={watch('showOnTour') ? 'Ativo' : 'Inativo'}
              error={errors.showOnTour?.message as string}
              form={register('showOnTour', { value: true })}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className="row justify-end pt-s-400" style={{ gap: 16 }}>
          <Button design="transparent" onClick={props.closeModal}>
            Cancelar
          </Button>
          <Button isLoading={isSubmitting || isLoading} type="submit">
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
}
